.register-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
  color: white;
}

.register-container.confirmation {
  text-align: center;
}

.register-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 30px 20px;
  padding-top: 40px;
  width: 250px;
  /* height: 620px; */
  border: 1px solid white;
}

.register-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 390px;
}

.terms {
  padding-top: 10px;
}

.register-box h1 {
  color: white;
  font-size: 28px;
}

.register-form input {
  height: 40px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.register-box a:visited,
.register-box a:link {
  color: var(--yellow);
}

.register-button,
.confirm-code {
  background-color: var(--yellow);
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.register-button:hover,
.confirm-code:hover {
  opacity: 0.7;
}

.PhoneInputCountrySelectArrow {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.PhoneInputCountry {
  border: 1px solid white;
  padding: 4px;
}

.date-input {
  display: flex;
  flex-direction: row;
}

.date-input p {
  color: white;
  margin: 0;
  line-height: 20px;
  padding-left: 15px;
}

.confirmation-box {
  display: flex;
  flex-direction: column;
  height: 300px;
  align-items: center;
  justify-content: center;
}

.confirmation-box div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.confirmation-box input {
  height: 50px;
  font-size: 30px;
  width: 200px;
  margin-bottom: 20px;
}

.confirmation-box .send-new-code {
  text-decoration: underline;
  color: white;
  padding-top: 10px;
}
.confirmation-box .text-new-code {
  color: white;
}

.send-new-code.disabled-code {
  opacity: 0.2;
}

.edit-cooldown-message {
  color: white;
  margin: 0px;
  margin-top: 5px;
}

@media only screen and (min-width: 768px) {
  .register-box {
    padding: 20px 70px;
    padding-bottom: 40px;
    width: 270px;
    /* height: 570px; */
  }
}
