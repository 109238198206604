.padding {
  padding: 30px;
}

.promotions-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
}

.promotions-container-no-items {
  display: flex;
  justify-content: center;
  color: white;
}

.promotion-item {
  width: 220px;
  margin: 20px;
  text-align: center;
}

.promotion-item-img {
  width: 220px;
  height: auto;
  border-radius: 5px;
  background-color: white;
}

.promotion-item a:link,
.promotion-item a:visited {
  color: white;
  text-decoration: none;
}

.promotion-item a:hover {
  color: var(--yellow);
  text-decoration: underline;
}

.promotion-item-price {
  color: var(--yellow);
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.promotions-banner {
  text-align: center;
  margin-bottom: 30px;
}

.promotions-banner img {
  max-width: 1400px;
  width: 100%;
}

.promotions-img {
  text-align: center;
}

.promotions-img img {
  max-width: 1400px;
  width: 100%;
}

@media only screen and (min-width: 550px) {
  .promotion-item {
    width: 300px;
  }
  .promotion-item-img {
    width: 300px;
  }
}

@media only screen and (min-width: 700px) {
  .promotions-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 330px);
    grid-gap: 10px;
    justify-content: center;
  }
  .promotion-item-name {
    height: 40px;
  }
}
