.side_drawer {
  height: 220px;
  background: black;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
  position: fixed;
  top: 200px;
  left: 20px;
  width: 100%;
  max-width: 215px;
  z-index: 200;
  transform: translateX(-200%);
  transition: transform 0.3s ease-out;
}

.open {
  transform: translateX(0);
}

.side_drawer ul {
  height: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  margin: 0;
}

.side_drawer li {
  margin: 0.5rem 0;
}

.side_drawer a {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
}

.side_drawer a:hover,
.side_drawer a:active {
  color: var(--yellow);
}

.sidedrawer li {
  padding: 4px 16px;
  margin: 0 16px;
  font-variation-settings: 'wght' 500;
}

.cotizador {
  font-variation-settings: 'wght' 800;
  border: 2px solid #08d192;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.back-to-liquidos img {
  width: 100px;
  height: auto;
  /* padding-left: 10px; */
  /* padding-top: 5px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 550px) {
  .side_drawer {
    left: 50px;
  }
}

@media screen and  (min-width: 1025px) {
  .side_drawer {
    display: none;
  }
}
