.password-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  /* height: 500px; */
  justify-content: center;
  align-items: center;
  color: white;
}

.password-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 50px 20px;
  width: 250px;
  /* height: 250px; */
  border: 1px solid white;
}

.password-form {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: space-between;
  width: 100%;
}

.password-box h1 {
  color: white;
  font-size: 28px;
}

.password-form input {
  height: 40px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.password-box a:visited,
.password-box a:link {
  color: var(--yellow);
}

.password-button {
  background-color: var(--yellow);
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.password-button:hover {
  opacity: 0.7;
}

.password-link {
  margin-top: 15px;
}

.email-sent {
  text-align: center;
}

.email-sent img {
  width: 125px;
  height: 125px;
}

@media only screen and (min-width: 768px) {
  .password-box {
    padding: 50px 70px;
    width: 270px;
  }
}
