.edit-customer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
  color: white;
}

.edit-customer-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 30px 20px;
  padding-top: 40px;
  width: 250px;
  /* height: 620px; */
  border: 1px solid white;
}

.edit-customer-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 290px;
}

.edit-customer-box h1 {
  color: white;
  font-size: 28px;
}

.edit-customer-form input,
select {
  height: 40px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.edit-customer-box a:visited,
.edit-customer-box a:link {
  color: var(--yellow);
}

.edit-customer-button {
  background-color: var(--yellow);
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.edit-customer-button:hover {
  opacity: 0.7;
}

.PhoneInputCountrySelectArrow {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.PhoneInputCountry {
  border: 1px solid white;
  padding: 4px;
}

.date-input {
  display: flex;
  flex-direction: row;
}

.date-input p {
  color: white;
  margin: 0;
  line-height: 20px;
  padding-left: 15px;
}

.confirmation-box {
  display: flex;
  flex-direction: column;
  height: 300px;
  align-items: center;
  justify-content: center;
}

.back-profile-button {
  color: white;
  border: none;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}

.back-profile-button:hover {
  opacity: 0.7;
}

.send-new-code.disabled-code {
  opacity: 0.2;
}

.edit-cooldown-message {
  color: white;
  margin: 0px;
  margin-top: 5px;
}
.text-code-explanation {
  color: white;
}

@media only screen and (min-width: 768px) {
  .edit-customer-box {
    padding: 20px 70px;
    padding-bottom: 40px;
    width: 270px;
    /* height: 570px; */
  }
}
