.more {
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  width: auto;
}

.more-partner,
.more-fan-partner {
  width: 95%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding: 10px;
  margin: 0 auto;
}

.more-fan-partner {
  background-color: var(--yellow);
  color: black;
  position: relative;
}

.more-fan-partner .coming-soon {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 200px;
  height: auto;
}

.more-partner img,
.more-fan-partner img {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.more-partner h1,
.more-fan-partner h1 {
  font-size: 28px;
}

.more-partner .checkbox-item {
  width: 95%;
}

.checkbox-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  padding-bottom: 10px;
}

.checkbox-item p {
  padding-left: 10px;
  font-size: 22px;
  margin: 0;
}

.checkbox-item svg {
  min-width: 30px;
}

.youtube-popover {
  width: 89vw !important;
}

@media only screen and (min-width: 550px) {
  .more {
    padding: 70px 50px;
  }

  .more-partner,
  .more-fan-partner {
    width: 90%;
    padding: 30px;
    height: 500px;
  }

  .more-partner h1,
  .more-fan-partner h1 {
    font-size: 36px;
  }

  .checkbox-item p {
    font-size: 22px;
  }
}

.register-now {
  margin-top: 30px;
  margin-left: 40px;
}

@media only screen and (min-width: 768px) {
  .more-partner .checkbox-item {
    width: 60%;
  }

  .more-fan-partner .coming-soon {
    right: 30px;
    bottom: 30px;
    width: 300px;
  }

  .checkbox-item {
    width: 60%;
  }

  .youtube-popover {
    width: 640px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .more {
    flex-direction: row;
    padding: 50px 50px;
  }

  .more-partner,
  .more-fan-partner {
    width: 50%;
  }

  .checkbox-item {
    width: 95%;
  }
}
