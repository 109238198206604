.profile-container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 150px;
}

.profile-info {
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile-info-row p,
.profile-info-row h2 {
  color: white;
  margin: 5px 0px;
}

.subscription-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: white;
  width: 220px;
}

.subscription-button:hover,
.fan-page-button:hover,
.profile-wallet-button:hover,
.edit-info-button:hover {
  opacity: 0.7;
}

.activate-subscription {
  background-color: green;
}

.cancel-subscription {
  background-color: rgb(155, 85, 85);
}

.profile-subscriptions {
  padding-left: 0px;
  height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fan-subscription-profile {
  padding-top: 15px;
}

.fan-page-button {
  background-color: var(--yellow);
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  width: 220px;
}

.wallet-profile {
  padding-top: 15px;
}

.profile-wallet-button {
  background-color: var(--yellow);
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 220px;
}

.profile-wallet-button img {
  width: 30px;
  height: auto;
}

.edit-profile {
  padding-top: 15px;
}

.edit-info-button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  width: 220px;
}

.normal-subscription-profile p {
  margin-bottom: 0;
}

.right-side-profile {
  padding-top: 25px;
}

.normal-subscription-profile .error-message {
  margin-bottom: 5px;
}

.popover-cancel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 300px;
  padding: 20px;
}

.popover-cancel-content h2,
.popover-cancel-content p {
  text-align: center;
}

.popover-cancel-content .confirm-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  padding: 10px 0px;
}

.confirm-buttons button {
  width: 100px;
  height: 30px;
  border-radius: 5px;
  color: white;
}

.confirm-buttons .confirm-cancel {
  background-color: #9b5555;
}

.confirm-buttons .stop-cancel {
  background-color: grey;
}

.last-warning {
  color: #9b5555;
  font-size: 15px;
}

.sorry-message {
  margin-bottom: 50px;
}

.wallet-icon {
  margin-right: 20px;
}

.edit-profile.first-button {
  padding-top: 0px;
}

@media only screen and (min-width: 650px) {
  .profile-subscriptions {
    padding-left: 50px;
    height: 400px;
  }

  .profile-container {
    flex-direction: row;
  }

  .right-side-profile {
    padding-top: 0px;
  }
}
