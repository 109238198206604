.wallet-payment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 500px;
}

.wallet-amount-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.wallet-amount-container h2 {
  color: white;
}

.wallet-amount-container input {
  height: 30px;
  font-size: 16px;
  padding-left: 10px;
}

.select-payment h2 {
  text-align: left;
}

.oneclick-button button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--yellow);
  text-decoration: underline;
  width: 100%;
  align-items: flex-start;
}

.oneclick-button button h2 {
  margin: 0;
  font-size: 16px;
}

.choose-card-wallet {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
}

.choose-card-wallet select {
  height: 35px;
  font-size: 16px;
  padding-left: 10px;
  width: 182px;
}

.card-radio-option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.wallet-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  color: white;
  text-align: center;
  padding-bottom: 70px;
}

.oneclick-button {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.payment-method-option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;
}

.payment-method-option img {
  padding-left: 15px;
  height: auto;
  width: 150px;
}

.khipu-waiting-confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.back-button {
  color: white;
  border: none;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}
