.loading-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.logo {
  width: 300px;
  height: 66px;
}