.toggle_button {
  height: 30px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  padding: 0;
  margin-right: 20px;
  box-sizing: border-box;
}

.toggle_button_line {
  width: 30px;
  height: 2px;
  background: white;
}

@media (min-width: 1025px) {
  .toggle_button {
    display: none;
    margin-right: 20px;
  }
}
