.navbar {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: white;
}

.notification-navbar-message {
  background: var(--yellow);
  width: 100%;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.notification-navbar-message h2 {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
}

.black-clubliquidos-img {
  height: 20px;
  width: 110px;
  padding: 20px 0;
}

.span-message {
  margin: 0;
}

.navbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 140px;
}

.navbar-container-border {
  border-bottom: 3px solid white;
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
  width: auto;
}

.navbar-title {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  height: 100%;
  justify-content: flex-end;
}

.navbar-title h1 {
  font-size: 50px;
  margin: 0;
}

.navbar-title img {
  width: 160px;
  margin-bottom: -10px;
  height: auto;
}

.navbar-items-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 70%;
  padding-right: 20px;
  height: 100%;
  font-size: 20px;
}

.navbar-item img {
  width: 100px;
  height: auto;
  padding-left: 10px;
  padding-top: 5px;
}

.navbar-item a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar-item a,
.navbar-item a:link,
.navbar-item a:visited {
  color: white;
  text-decoration: none;
}

button.navbar-item-login,
.navbar-item-login a:link,
.navbar-item-login a:visited {
  text-decoration: none;
  color: black;
  font-weight: 500;
  background-color: var(--yellow);
  height: 40px;
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}

.navbar-item.highlight a {
  color: var(--yellow);
}

.navbar-items-container a:hover,
.navbar-items-container button:hover {
  opacity: 0.7;
}

.burger {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px;
}

.navbar-item {
  display: none;
}

@media only screen and (min-width: 550px) {
  .navbar-container-border {
    margin-left: 50px;
    margin-right: 50px;
  }

  .burger {
    padding-left: 50px;
  }

  .navbar-items-container {
    padding-right: 50px;
  }

  .navbar-title h1 {
    font-size: 60px;
  }

  .navbar-title img {
    width: 200px;
    margin-bottom: -10px;
  }
}

@media only screen and (min-width: 1024px) {
  .burger {
    display: none;
  }

  .navbar-title {
    padding-left: 50px;
  }

  button.navbar-item-login,
  .navbar-item-login a:link,
  .navbar-item-login a:visited {
    margin-bottom: -12.5px;
    height: 50px;
    width: 110px;
    font-size: 20px;
  }

  .navbar-item {
    display: unset;
  }

  .navbar-items-container {
    justify-content: space-between;
  }
}
