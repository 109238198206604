.wallet-container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.wallet-box {
  background-color: var(--grey);
  width: 250px;
  height: 170px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.wallet-title-container {
  background-color: var(--yellow);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  color: black;
  position: relative;
  height: 70px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  width: 280px;
}

.wallet-title-container .wallet-title-border-cut {
  position: absolute;
  height: 25px;
  left: 0px;
  right: 0px;
  top: 65%;
  z-index: 10;
  background-color: var(--grey);
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  width: 100%;
}

.wallet-title {
  display: none;
  flex-direction: row;
  align-items: center;
}

.wallet-title h1 {
  margin: 15px 0px;
  font-size: 28px;
}

.wallet-title img {
  width: 40px;
  height: 40px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.wallet-rounded {
  width: 90px;
  border-radius: 70px;
  height: 20px;
  z-index: 15;
  background-color: var(--yellow);
}

.wallet-action-container {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.wallet-info {
  width: 50%;
  padding-top: 30px;
}

.wallet-info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 40px;
  padding-bottom: 20px;
}

.wallet-info-row h2 {
  margin: 0;
  font-size: 20px;
}

.wallet-buttons {
  padding-top: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.wallet-buttons .yellow-button {
  width: 250px;
  margin-bottom: 20px;
}

.wallet-dots {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  width: 70px;
  justify-content: space-between;
}

.wallet-single-dot {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background-color: var(--yellow);
}

.mobile-wallet-title h1 {
  font-size: 30px;
  margin-bottom: 10px;
}

.mobile-wallet-action-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
}

.mobile-wallet-info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 250px;
  padding-bottom: 10px;
}

.mobile-wallet-info-row h2 {
  margin: 0;
}

.mobile-wallet-buttons {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: space-between;
  align-items: center;
}

.mobile-wallet-buttons button {
  width: 250px;
}

.success-message {
  color: green;
  font-size: 24px;
}

.failure-message {
  color: red;
  font-size: 24px;
}

.explain-reserved {
  padding: 20px 40px 20px;
  display: block;
}

@media only screen and (min-width: 625px) {
  .wallet-dots {
    width: 100px;
  }

  .wallet-single-dot {
    height: 20px;
    width: 20px;
  }

  .wallet-box {
    width: 460px;
    height: 300px;
    border-radius: 70px;
    padding: 40px;
  }

  .wallet-title-container {
    height: 70px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    width: 460px;
  }

  .mobile-wallet-title h1 {
    font-size: 50px;
    margin-bottom: 60px;
  }

  .wallet-title-container .wallet-title-border-cut {
    height: 30px;
    top: 65%;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
  }

  .wallet-rounded {
    width: 150px;
    border-radius: 70px;
    height: 25px;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile-wallet-action-container {
    display: none;
  }

  .wallet-title {
    display: flex;
  }

  .wallet-action-container {
    display: flex;
  }

  .wallet-box {
    width: 660px;
    height: 420px;
    border-radius: 70px;
    padding: 40px;
  }

  .wallet-title-container {
    height: 110px;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    width: 660px;
  }

  .mobile-wallet-title {
    display: none;
  }

  .wallet-title-container .wallet-title-border-cut {
    height: 50px;
    top: 65%;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
  }

  .wallet-rounded {
    width: 250px;
    border-radius: 70px;
    height: 40px;
  }
}