.tbk-final-container,
.tbk-reject-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 600px;
  padding-top: 50px;
}

.tbk-final-info {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 250px;
  text-align: center;
}

.tbk-reject-info {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 300px;
  text-align: center;
}

.tbk-reject-info h1,
.tbk-reject-info h2 {
  margin: 0;
}

.tbk-final-info img,
.tbk-reject-info img {
  width: 100px;
  height: auto;
}

.tbk-payment-info {
  padding-bottom: 30px;
}

.tbk-payment-info li p {
  text-align: left;
  font-size: 22px;
}
