.home-container {
  color: white;
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  width: auto;
}

.welcome-container {
  width: 100%;
  background-color: var(--grey);
  height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.welcome-buttons {
  width: auto;
  display: flex;
  height: 110px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.welcome-buttons button {
  background-color: var(--yellow);
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 200px;
}

.welcome-buttons button:hover {
  opacity: 0.7;
}

.welcome-youtube-icon {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.welcome-logo {
  width: 130px;
}

.welcome-title {
  width: 260px;
}

.welcome-title h1 {
  font-size: 42px;
  margin: 0;
  line-height: 50px;
}

.welcome-side-container {
  width: 100%;
  background-color: var(--yellow);
  height: 560px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-news {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50%;
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.welcome-news h1 {
  font-size: 146px;
  margin: 0;
  margin-bottom: 50px;
}

.welcome-news h2 {
  font-size: 36px;
  text-align: center;
}

.welcome-wallet {
  height: 50%;
  border-top: 2px solid black;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.welcome-wallet img {
  width: 100px;
  height: auto;
}

.welcome-wallet h1 {
  font-size: 36px;
}

.youtube-popover {
  width: 89vw !important;
}

@media only screen and (min-width: 1260px) {
  .welcome-news h1 {
    font-size: 196px;
    margin-bottom: 50px;
  }

  .welcome-news h2 {
    font-size: 46px;
  }

  .welcome-wallet img {
    width: 130px;
  }

  .welcome-wallet h1 {
    font-size: 42px;
  }
}

@media only screen and (min-width: 1024px) {
  .home-container {
    flex-direction: row;
  }

  .welcome-container {
    width: 70%;
  }

  .welcome-side-container {
    width: 30%;
  }

  .welcome-logo {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) {
  .welcome-buttons {
    width: 600px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .youtube-popover {
    width: 640px !important;
  }
}

@media only screen and (min-width: 550px) {
  .welcome-title {
    width: 340px;
  }

  .welcome-title h1 {
    font-size: 56px;
    margin: 0;
    line-height: 64px;
  }

  .home-container {
    padding: 50px 50px;
  }
}
