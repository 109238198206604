.edit-addresses-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
  color: white;
}

.edit-addresses-button {
  margin-top: 40px !important;
}

.edit-addresses-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 15px 10px;
  padding-top: 20px;
  width: 250px;
  /* height: 620px; */
  border: 1px solid white;
}

.edit-addresses-button {
  background-color: var(--yellow);
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  margin: 10px;
}

.edit-addresses-button:hover {
  opacity: 0.7;
}

.edit-address-button {
  background-color: white;
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin: 10px;
}

.delete-address-button {
  background-color: rgb(155, 85, 85);
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.popover-delete-address-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 200px;
  padding: 20px;
}

.popover-delete-address-content h2,
.popover-delete-address-content p {
  text-align: center;
}

.popover-delete-address-content .confirm-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 210px;
  padding: 10px 0px;
}

.addresses-msg {
  display: flex;
  justify-content: center;
  text-align: center;
  width: inherit;
}

.edit-addresses-box .error-message {
  margin: 0;
  padding-left: 6px;
}

.edit-addresses-box .back-profile-button {
  margin-top: 10px;
}

.edit-addresses-box ul {
  list-style-type: none;
  padding-left: 0px;
}

@media only screen and (min-width: 768px) {
  .edit-addresses-box {
    padding: 10px 20px;
    padding-bottom: 30px;
    width: 400px;
    /* height: 570px; */
  }
  .popover-delete-address-content {
    width: 270px;
  }
}
