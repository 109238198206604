:root {
  --yellow: #fae70d;
  --grey: #1b1a1a;
}

body {
  font-family: "Oswald";
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: black;
}

.page-sub-container {
  flex: 1;
}

.error-message {
  color: #f55b5b;
  min-height: 25px;
  height: auto;
  /* margin-top: 0px; */
}

button {
  font-family: unset;
  font-size: unset;
  display: unset;
  cursor: pointer;
  border: none;
  appearance: none;
  color: unset;
  background: unset;
}

button:active,
button:focus {
  border: none;
  outline: none;
}

.yellow-button {
  background-color: var(--yellow);
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.yellow-button:hover {
  opacity: 0.7;
}
